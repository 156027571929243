<template>
  <div class="uebersichtkueche">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('kitchen.title') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
        </va-card>
      </div>
    </div>
    <div class="row" v-for="(ev,evK) in workEventList" :key="evK">
      <div class="flex md12">
        <va-card>
          <va-accordion>
            <va-collapse>
              <div class="row" slot="header">
                <div class="flex md2">
                  {{ev.eventStart}} - {{ev.eventEnd}}
                </div>
                <div class="flex md3">
                  {{ev.eventDescr}}
                </div>
                <div class="flex md2 flex-center">
                  angemeldet: Woche: {{ev.sumWorkersCount}} Pers.
                </div>
              </div>
              <div class="row" slot="body">
                <div class="row">
                  <div class="flex md2">
                    <va-button small @click="getKitchenWorkers(ev, $event)" color="info"> {{ $t('common.update') }}</va-button>
                  </div>
                  <div class="va-table-responsive">
                    <table class="va-table" id="va-table-kitchenOverview">
                      <thead>
                        <tr>
                          <th>{{ $t('common.date') }}</th>
                          <th>{{ $t('kitchen.breakfast') }}</th>
                          <th>{{ $t('kitchen.lunch') }}</th>
                          <th>{{ $t('kitchen.supper') }}</th>
                          <th>{{ $t('kitchen.cake') }}</th>
                          <th>{{ $t('common.comment') }}</th>
                          <th>{{ $t('common.registrant') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="subs in ev.kitchenEventsDet" :key="subs.eventDay">
                          <td>{{ subs.eventDay }}</td>
                          <td><span v-html="subs.breakfast"></span></td>
                          <td><span v-html="subs.lunch"></span></td>
                          <td><span v-html="subs.supper"></span></td>
                          <td><span v-html="subs.cake"></span></td>
                          <td><span v-html="subs.comments"></span></td>
                          <td v-if="subs.orgUsers"><span v-html="subs.orgUsers"></span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </va-collapse>
          </va-accordion>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'uebersichtkueche',
  data () {
    this.getEvents()
    return {
      workEventList: {},
      currState: {
        msgType: '',
        msg: '',
      },
    }
  },
  methods: {
    getEvents () {
      this.$srvconn.sendMessage('getKitchenEvents')
        .then(response => {
          if (response.msgType === 'getKitchenEvents') {
            this.workEventList = response.userData.kitchenEvents
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getKitchenWorkers (ev, evObj) {
      ev.kitchenEventsDet = []
      var usrData = {
        eventID: ev.eventID,
      }
      this.$srvconn.sendMessage('getKitchenWorkers', usrData)
        .then(response => {
          if (response.msgType === 'getKitchenWorkers') {
            ev.kitchenEventsDet = response.userData.kitchenEventsDet
            if (evObj.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.__vue__) {
              evObj.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.__vue__.collapse()
              evObj.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.__vue__.expand()
            }
          }
        })
        .catch(err => {
          console.log('ERROR: ' + err)
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
  },
}
</script>

<style>
  #va-table-kitchenOverview td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid #dddddd;
  }

  #va-table-kitchenOverview tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #va-table-kitchenOverview {
    margin-bottom: 1rem;
  }
</style>
